﻿const tmpApiDomain = import.meta.env.VITE_BASE_API;
const domainPrefix = 'cms/';

// const devApiDomain = 'https://api-mircrosoft-building.dankal.cn/v1/cms/' // 开发环境api域名
// const proApiDomain = 'https://apirunworld.mctower.cn/v1/cms/' // 生产环境api域名

export const apiDomain = tmpApiDomain + domainPrefix;

export const uploadDomain = '/property/common/upload'; // 腾讯云

export const imgDomain = import.meta.env.VITE_TX_COS_URL; // 图片域名,切换新上传接口，url返回域名无需拼接
