/**
 * Created by IvanCai on 2017/4/19.
 * Modified by Zhi on 2018/7/8.
 */
import axios from 'axios';
import { apiDomain } from '@enterpaise/configs/environment';

const dkAxios = axios.create({
    baseURL: apiDomain,
});

let loading;
dkAxios.interceptors.request.use(
    config => {
        // 访问网络时加载loading,防止用户多次操作
        const token = sessionStorage.getItem('X-Access-Token');
        config.headers = {
            'X-Access-Token': token || 'dac1b3fb3aa14f92cf425f171c972213',
        };

        loading = vm.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
        });

        return config;
    },
    error => Promise.reject(error)
);

dkAxios.interceptors.response.use(
    response => {
        loading.close();
        return response;
    },
    error => {
        console.info(error.response, '=======');
        loading.close();

        if (error.response) {
            console.info(
                error.response.config.url.indexOf('multipart_add_staff')
            );
        }
        /*   if (error.response.config.url.indexOf('multipart_add_staff') !== -1) {
      console.info(error.response, '-----------------')
      vm.$confirm(error.response.data.error.message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
      }).then(() => {
      }).catch(() => {
      });
      return Promise.reject(error);
    } */
        const { status, data } = error.response;

        switch (status) {
            case 401:
            case 412:
                vm.$router.replace('/login');
                break;
            default:
                vm.$message.error(data.error.message);

                return Promise.reject(error);
        }
    }
);

export default dkAxios;
